































import LazyHydrate from "vue-lazy-hydration";
import {
  useRoute,
  defineComponent,
  ref,
  // onBeforeUnmount,
  // useAsync,
  // useContext,
} from "@nuxtjs/composition-api";
import { useUiState } from "~/composables";
import AppHeader from "~/components/AppHeader.vue";
import IconSprite from "~/components/General/IconSprite.vue";
import LoadWhenVisible from "~/components/utils/LoadWhenVisible.vue";
import TopBar from "~/components/TopBar/TopBar.vue";
import Ups from "~/components/Ups.vue";
import Megamenu from "~/components/Megamenu.vue";
import TimedAlert from "~/components/TimedAlert.vue";
import Notification from "~/components/Notification.vue";
import Alert from "~/components/Alert.vue";
// import CookieBanner from "~/components/CookieBanner.vue";
// import { useConfigStore } from "~/stores/config";

export default defineComponent({
  name: "DefaultLayout",
  middleware({ app, redirect }) {
    if (app.context.route.query.token) {
      app.context.$cookies.remove("vsf-customer");
      app.context.$cookies.remove("sale-person");
      app.context.$cookies.set("vsf-customer", app.context.route.query.token);
      app.context.$cookies.set(
        "sale-person",
        app.context.route.query.salesperson_session
      );

      return redirect("/");
    }
  },
  head() {
    return {
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
    };
  },
  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    TimedAlert,
    // CookieBanner,
    IconSprite,
    TopBar,
    Ups,
    Megamenu,
    Notification,
    Alert,
    CartSidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/checkout/components/CartSidebar.vue"
      ),
    AppFooter: () =>
      import(
        /* webpackPrefetch: true */ "~/components/storyblok/AppFooter.vue"
      ),
    WishlistSidebar: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/wishlist/components/WishlistSidebar.vue"
      ),
    LoginModal: () =>
      import(
        /* webpackPrefetch: true */ "~/modules/customer/components/LoginModal/LoginModal.vue"
      ),
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
    } = useUiState();

    const isSalesPerson = ref(null);
    // const configStore = useConfigStore();
    // const { app } = useContext();

    // useAsync(async () => {
    //   const cookieConsentValue = app.$cookies.get("cookieConsent");

    //   if (cookieConsentValue == null) {
    //     await configStore.setShowCookieBanner(true);
    //   } else {
    //     await configStore.setShowCookieBanner(false);
    //   }
    // });

    // onBeforeUnmount(() => {
    //   configStore.setShowCookieBanner(false);
    // });

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      isSalesPerson,
    };
  },
  mounted() {
    const reloadFlag = this.$cookies.get("reloadPage");
    if (reloadFlag) {
      this.$cookies.remove("reloadPage");
      window.location.reload();
    }

    const salesCookie = this.$cookies.get("sale-person");
    if (salesCookie) {
      return;
    } else {
      this.isSalesPerson = false;
    }

    // When the user scrolls the page, execute myFunction
    window.onscroll = function () {
      myFunction();
    };

    // Get the header
    var header = document.getElementById("myHeader");
    var mainContent = document.getElementById("upsLayout");

    // Get the offset position of the navbar
    var sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      let scrollPosition =
        window.scrollY ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if (scrollPosition > sticky) {
        header.classList.add("sticky");
        mainContent.classList.add("pt-[20px]");
      } else {
        header.classList.remove("sticky");
        mainContent.classList.remove("pt-[20px]");
      }
    }
  },
});
