

























import { defineComponent } from "@nuxtjs/composition-api";
import type { PropType } from "@nuxtjs/composition-api";
import { SfButton } from "@storefront-ui/vue";
import type { NuxtError } from "@nuxt/types";
import { useFetch, ref, useContext, computed } from "@nuxtjs/composition-api";
import { getMetaInfo } from "~/helpers/getMetaInfo";
import Error404 from "~/components/storyblok/Error404.vue";

export default defineComponent({
  components: {
    SfButton,
    Error404,
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  setup() {
    const story = ref({ content: {} });
    const { app } = useContext();
    const breadcrumbs = ref([
      {
        link: app.localePath("/"),
        text: app.i18n.t("Home"),
      },
      {
        link: app.localePath("/error404"),
        text: app.i18n.t("error404"),
      },
    ]);

    const baseUrl = "/error404";
    const locale = app.i18n.locale;

    useFetch(async () => {
      await app.$storyblok("error404").then((res) => {
        story.value.content = res.story.content;
      });
    });

    return {
      story,
      breadcrumbs,
      baseUrl,
      locale,
    };
  },
  head() {
    return getMetaInfo(this.metatags, this.locale, this.baseUrl);
  },
});
